import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";

function CodeBlock({ children, language }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      // setTimeout and set back to not copied
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied, setCopied]);

  return (
    <div className="shared__code-block">
      <SyntaxHighlighter language={language} style={atomDark}>
        {children}
      </SyntaxHighlighter>
      <CopyToClipboard onCopy={() => setCopied(true)} text={children}>
        <span className="labeltext shared__copy-clipboard">
          {copied ? "Copied!" : "Copy"}
        </span>
      </CopyToClipboard>
    </div>
  );
}

CodeBlock.propTypes = {
  language: PropTypes.string,
  children: PropTypes.node,
};

CodeBlock.defaultProps = {
  language: "javascript",
  children: null,
};

export default CodeBlock;
